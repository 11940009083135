export const flatten = (data) => {
  return data.reduce(function (result, next) {
    next.depth = next.depth + 1
    result.push(next)
    if (next.childNodes.length > 0) {
      result = result.concat(flatten(next.childNodes))
      next.childNodes = []
      next.disabled = true
    }
    return result
  }, [])
}

export const flattenHierarchyTree = (source) => {
  let data = JSON.parse(JSON.stringify(source))
  return data.reduce((result, next) => {
    next.depth = next.depth + 1
    result.push(next)
    if (next.children.length > 0) {
      result = result.concat(flattenHierarchyTree([...next.children]))
      next.children = []
      next.disabled = true
    }
    return result
  }, [])
}

export const createDataTree = (dataset) => {
  const hashTable = Object.create(null)
  dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, childNodes: [] }))
  const dataTree = []
  dataset.forEach((aData) => {
    if (aData.parentId) hashTable[aData.parentId].childNodes.push(hashTable[aData.id])
    else dataTree.push(hashTable[aData.id])
  })
  return dataTree
}
